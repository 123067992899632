@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1a1a1a;
  --color-bg-variant: #8f96a3;
  --color-bg-primary: #34e4ea;
  --color-bg-primary-variant: #13b3b9;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url("../src/assets/tactile_noise.png");
}
